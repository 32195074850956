import React from 'react'
import { useStaticQuery } from "gatsby"
import Layout from '../components/layout'
import Footer from '../components/footer'
import Intro from '../components/intro'
import Img from "gatsby-image"

const ProjectPage = () => {

    const data = useStaticQuery(graphql`
    query {
        allContentfulProjecten {
            edges {
              node {
                link
                projectImg {
                    fluid(maxWidth: 500, quality: 40) {
                        sizes
                        src
                        aspectRatio
                        srcSet
                        srcSetWebp
                        srcWebp
                      }
                }
                projectName
                location
                projectYear
              }
            }
          }
    }
  `)

    return (
        <Layout>
            <Intro title="Onze opdrachtgevers" text="Onze opdrachtgevers zijn o.a. projectontwikkelaars, architecten, bouwbedrijven en adviesbureaus. Stuk voor stuk gerenommeerde bedrijven die hun kennis en ervaring willen delen met iedereen die beroepsmatig betrokken is bij bouwen en bouwprojecten. De wederzijdse betrokkenheid is, zeker als het gaat om een langdurige samenwerking, groot." />
            <div className="projects-wrapper">
                <div className="inner">
                    <div className="row">
                        {data.allContentfulProjecten.edges.map((edge, i) => {
                            return (
                                <div className="col-xs-12 col-sm-6 col-lg-3 ref" key={i}>
                                    <div className="project-wrapper">
                                        <Img className="project" fluid={edge.node.projectImg.fluid} />
                                        <a href={edge.node.link} target="_blank" rel="noreferrer">
                                            <div className="overlay-project">
                                                <span>{edge.node.projectName}</span><br></br>
                                                <span className="under-text">{edge.node.location} - {edge.node.projectYear}</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default ProjectPage;